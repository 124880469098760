/** Angular */
import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { DMYDatePipe } from "../../layout";
import moment from "moment";

@Injectable()
export class TypesUtilsService {
  constructor(private datePipe: DatePipe, private dmyDatePipe: DMYDatePipe) {}

  formatDate(date: any, format: string = "dd-MM-yyyy"): string | null {
    if (!date) {
      return null;
    }

    const isMomentDateValid = (d: any): boolean => {
      return moment(d).isValid();
    };

    const isDateValid = (d: any): boolean => {
      return d instanceof Date || (typeof d === "string" && !isNaN(Date.parse(d)));
    };

    if (!isDateValid(date) && !isMomentDateValid(date)) {
      console.warn(`Invalid date provided: ${date}`);
      return null;
    }

    return this.datePipe.transform(date, format);
  }

  getDateFromString(dateInStr: string = "", returnDateIfNoValue = true): null | string | Date {
    if (dateInStr) {
      return this.dmyDatePipe.transform(dateInStr);
    }

    return returnDateIfNoValue ? new Date() : null;
  }

  static economicAccountDisplayFn(account: any): string {
    return account?.entity_object ? (account.entity_id || "") + " - " + account.entity_object.name : "";
  }
}
