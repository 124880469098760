import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PatchNoteService } from "../../../../pages/patch-note/patch-note.service";
import { INoteDetails } from "../../../../pages/patch-note/patch-note.interface";
import { map } from "rxjs/operators";

@Component({
  selector: "kt-marquees",
  templateUrl: "./marquees.component.html",
  styleUrls: ["./marquees.component.scss"],
})
export class MarqueesComponent implements OnInit {
  message: string | null = null;
  marqueeMessage$: Observable<string>;

  constructor(private _tenantMessagesService: PatchNoteService) {}

  ngOnInit(): void {
    this.marqueeMessage$ = this._tenantMessagesService.getAnnouncements().pipe(
      map((response: { data: INoteDetails[] }) => {
        return (this.message = response.data
          .map((item: { message: string }) => item.message)
          .join("<span class='text-primary'> |•••| </span>"));
      })
    );
  }

  closeMarqueeMessage() {
    this.message = null;
  }
}
