<div class="d-flex marquees" *ngIf="marqueeMessage$ | async">
  <marquee>
    <p [innerHTML]="message"></p>
  </marquee>
  <div *ngIf="message">
    <button type="button" class="btn p-0 text-primary border-0" aria-label="Close" (click)="closeMarqueeMessage()">
      <i class="fas fa-times-circle small"></i>
    </button>
  </div>
</div>
