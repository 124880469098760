import { ErrorHandler, Inject, Injectable, InjectionToken } from "@angular/core";
import { Router } from "@angular/router";
import * as Rollbar from "rollbar";
import { environment } from "../../../../../environments/environment";

const rollbarConfig = {
  enabled: true,
  accessToken: environment.RollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.AppName,
  addErrorContext: true,
  nodeSourceMaps: true,
  inspectAnonymousErrors: true,
  payload: {
    custom_data: {
      Error_happened_on: `Error Accrue on  ${environment.AppName} with app version ${environment.appVersion}`,
    },
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.appVersion,
        guess_uncaught_frames: true,
      },
    },
  },

  context: {
    URL: window.location.href,
    URLSearchParams: window.location.search,
    URLHost: window.location.host,
    URLPathname: window.location.pathname,
  },
};

export const RollbarService = new InjectionToken<Rollbar>("rollbar");

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar, private router: Router) {}

  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const unAuthenticatedMessage = /Unauthenticated/;

    if (chunkFailedMessage.test(err.message)) {
      window.location.reload(); // Reload page for Loading chunk errors
    }

    // check if reloading is better
    if (unAuthenticatedMessage.test(err.message)) {
      this.rollbar.info(err.message);
      return;
    }

    // if (!location.hostname.includes("localhost")) {
    if (err.originalError || err.name != "HttpErrorResponse") {
      console.error(err);
      this.rollbar.error(err);
    }
    // }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
