import { YearPickerDirective } from "./../../core/_base/layout/directives/year-picker.directive";
// Angular
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from "ng2-currency-mask";
import { NgxPermissionsModule } from "ngx-permissions";
// Translation
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DMYDatePipe, SafePipe, StylePaginatorDirective } from "../../core/_base/layout";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
//import { NativeDateAdapter } from "@angular/material";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from "@angular/material/tooltip";
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
// Perfect Scrollbar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// Core module
import { CoreModule } from "../../core/core.module";
import { PaginatorI18n } from "../../core/_config/paginator.config";
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  DatatableFilterDropdownComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from "./content/crud";
// Layout partials
import {
  ActionsMenuComponent,
  ContextMenu2Component,
  ContextMenuComponent,
  GeneralSearchComponent,
  LanguageSelectorComponent,
  NotificationComponent,
  QuickActionComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  StickyToolbarComponent,
  Subheader1Component,
  Subheader2Component,
  Subheader3Component,
  SubheaderSearchComponent,
  UserProfileComponent,
} from "./layout";
// General
import { NoticeComponent } from "./content/general/notice/notice.component";
import { PortletModule } from "./content/general/portlet/portlet.module";
// Errpr
import { ErrorComponent } from "./content/general/error/error.component";
// Extra module
import { WidgetModule } from "./content/widgets/widget.module";
import { ClickOutsideModule } from "ng-click-outside";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
import { CartComponent } from "./layout/topbar/cart/cart.component";
// Dropzone
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from "ngx-dropzone-wrapper";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SupplierEditComponent } from "../pages/suppliers/supplier-edit.component";
import { InvoiceStatusButtonComponent } from "./components/invoice-status-button/invoice-status-button.component";
import { SafeHtmlPipe } from "../../core/_base/layout/pipes/safe-html.pipe";

// export class AppDateAdapter extends NativeDateAdapter {

// 	parse(value: any): Date | null {
//         if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
//           const str = value.split('-');
//           const year = Number(str[2]);
//           const month = Number(str[1]) - 1;
// 		  const date = Number(str[0]);
//           return new Date(year, month, date);
// 		}
// 		//const timestamp = typeof value === 'number' ? value : Date.parse(value);
// 		//return isNaN(timestamp) ? null : new Date(timestamp);
// 		if(value.length <= 2 && Number(value) <= 31){
// 			return new Date(new Date().getFullYear(), new Date().getMonth(), value);
// 		}
// 		else if(value.length == 3){
// 			return new Date(new Date().getFullYear(), value.slice(2,3) -1, value.slice(0,2));
// 		}
//       }

// 	format(date: Date, displayFormat: Object): string {
// 	  const day = date.getDate();
// 	  const month = date.getMonth() + 1;
// 	  const year = date.getFullYear();
// 		if (displayFormat === 'input') {
// 			return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year;
// 		  }
// 		  //return date.toDateString();
// 		  return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year;
// 	}

// 	private _to2digit(n: number) {
// 		return ('00' + n).slice(-2);
// 	}
// }

// export const APP_DATE_FORMATS =
// {
//   parse: {
// 	  dateInput: {day: 'numeric',month: 'short', year: 'numeric'}
//   },
//   display: {
//       dateInput: 'input',
//       monthYearLabel: {year: 'numeric', month: 'short'},
//       dateA11yLabel: {year: 'numeric', month: 'short', day: 'numeric'},
//       monthYearA11yLabel: {year: 'numeric', month: 'short'},
//   }
// };

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM-YYYY",
  },
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://spark-api.b5digital.dk/api/",
  acceptedFiles: "image/*",
  maxFilesize: 20,
  autoProcessQueue: false,
  addRemoveLinks: true,
  dictRemoveFile: '<i class="fas fa-times-circle"></i>',
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
};

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 10,
  hideDelay: 10,
  touchendHideDelay: 1000,
  position: "above",
};

const matDialogDefaultOptions: MatDialogConfig = {
  scrollStrategy: new NoopScrollStrategy(),
  hasBackdrop: true,
};

@NgModule({
  declarations: [
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    DatatableFilterDropdownComponent,
    SafeHtmlPipe,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    InvoiceStatusButtonComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    ActionsMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    GeneralSearchComponent,
    UserProfileComponent,
    CartComponent,
    ErrorComponent,
    //pipes
    DMYDatePipe,
    SafePipe,
    StylePaginatorDirective,
    SupplierEditComponent,
    YearPickerDirective,
  ],
  exports: [
    WidgetModule,
    SafeHtmlPipe,
    PortletModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    ClickOutsideModule,
    InlineSVGModule,
    NgbDropdownModule,
    //NgxPermissionsModule,
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    DatatableFilterDropdownComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    InvoiceStatusButtonComponent,
    AlertComponent,
    YearPickerDirective,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    ActionsMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    GeneralSearchComponent,
    UserProfileComponent,
    CartComponent,
    ErrorComponent,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatStepperModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    // external module
    DropzoneModule,
    TranslateModule,
    //pipes
    DMYDatePipe,
    SafePipe,
    StylePaginatorDirective,
    SupplierEditComponent,
    InvoiceStatusButtonComponent,
    InvoiceStatusButtonComponent,
  ],
  imports: [
    DragDropModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,
    ClickOutsideModule,
    NgxPermissionsModule.forChild(),
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatStepperModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    // external module
    DropzoneModule,
    TranslateModule.forChild(),
  ],

  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl(),
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: customTooltipDefaults,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: matDialogDefaultOptions,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class PartialsModule {}
