import { map } from "rxjs/operators";
// Angular
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// RxJS
import { Observable, Subject } from "rxjs";
import { API } from "../../core/_base/crud";
import { SettingCodes } from "./enums/settings/settingCodes";
import { EntityTypes } from "./enums/entity/entityTypes";
import { CustomerTypes } from "./enums/customer/customerTypes";
import { API_URL } from "../../core/_base/crud/utils/api";

@Injectable()
export class SharedService {
  public clearCustomerFormDataSubject = new Subject<void>();
  public clearCustomerSecondFormDataSubject = new Subject<void>();
  public systemTaskUpdatesSubject = new Subject<{
    taskCode: string;
    isCompleted: boolean;
  }>();

  constructor(private http: HttpClient) {}

  createVehicle(product): Observable<any> {
    return this.http.post<any>(API.vehicles, product);
  }

  getVehicleById(productId: number): Observable<any> {
    return this.http.get<any>(API.vehicles + `/${productId}`);
  }

  updateVehicle(product, productId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${productId}`, product);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getVehicleConditions(): Observable<any> {
    return this.http.get<any>(`${API.API_URL}/vehicle-conditions/getAll/list`);
  }

  getVehicleTypes(): Observable<any> {
    return this.http.get<any>(`${API.API_URL}/vehicle-types/getAll/list`, {
      params: {
        order_column: "id",
        order_dir: "asc",
      },
    });
  }

  getNoteCategories(): Observable<any> {
    return this.http.get<any>(`${API.API_URL}/note-categories/getAll/list`);
  }

  getSuppliers(q): Observable<any> {
    return this.http.get<any>(`${API.suppliers}/getAll/list?q=${q}`);
  }

  getAccounts(q): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=accounts&q=${q}`);
  }

  getFile(url): Observable<any> {
    return this.http.get<any>(url);
  }

  getCustomersList(params): Observable<any> {
    return this.http.get<any>(`${API.customers}/getAll/list`, { params });
  }

  getVehicleMakeList(q, vehicle_type_id) {
    return this.http.get<any>(`${API.vehiclesMakes}/getAll/list`, {
      params: {
        q,
        order_column: "name",
        order_dir: "asc",
        vehicle_type_id,
      },
    });
  }

  getMakeModelsList(q, make_id, vehicle_type_id) {
    return this.http.get<any>(`${API.vehiclesModels}/getAll/list`, {
      params: {
        q,
        order_column: "model",
        order_dir: "asc",
        make_id,
        vehicle_type_id,
      },
    });
  }

  registrationTaxCalculator(product, vehicleId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/tax-calculator`, product);
  }

  calculateEndAgreementProposedTaxValue(params): Observable<any> {
    return this.http.post<any>(`${API.contracts}/calculate/end-agreement-tax`, params);
  }

  getBilbasenAdInfo(payload): Observable<any> {
    return this.http.post<any>(`${API.bilbasen}/crawl-bilbasen-ad`, payload);
  }

  priceAdjustmentCalculator(product, vehicleId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/calc-price-adj`, product);
  }

  storeRegistrationTax(product, vehicleId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/add-reg-tax`, product);
  }

  deleteMarketPriceDocument(vehicleId, fileId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/delete/market-price-sets-docs`, { file_id: fileId });
  }

  downloadMarketPriceDocument(vehicleId, market_price_set_id): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/download/market-price-sets-docs`, {
      market_price_set_id: market_price_set_id,
    });
  }

  entityLeasingCalculator(product, entity_id, entity_type): Observable<any> {
    switch (entity_type) {
      case "contract":
        return this.http.post<any>(`${API.contracts}/${entity_id}/leasing-calculator`, product);
      case "offer":
        return this.http.post<any>(`${API.offers}/${entity_id}/leasing-calculator`, product);
    }
  }

  getProductsList(params): Observable<any> {
    return this.http.get<any>(`${API.products}/getAll/list`, { params });
  }

  calculateEntity(product, entity_id, entity_type): Observable<any> {
    // return this.http.post<any>(
    //   `${API.API_URL}/${entity_type}/${entity_id}/calculate`,
    //   product
    // );
    switch (entity_type) {
      case "contract":
        return this.http.post<any>(`${API.contracts}/${entity_id}/calculate`, product);
      case "offer":
        return this.http.post<any>(`${API.offers}/${entity_id}/calculate`, product);
    }
  }

  getInsurancePrice(productId, params): Observable<any> {
    return this.http.get<any>(`${API.insurancePrices}/product/${productId}/price`, { params });
  }

  /** Customer form */
  getUsers(q: string = "", params: any = {}, curPage: number = 1): Observable<any> {
    return this.http.get<any>(`${API.users}`, {
      params: {
        ...params,
        q: q,
        order_column: "name",
        order_dir: "asc",
        per_page: 7,
        page: curPage,
      },
    });
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }

  getRiskClassifications(q): Observable<any> {
    return this.http.get<any>(`${API.risk_classification}/getAll/list`, {
      params: {
        q,
      },
    });
  }

  createCustomer(product): Observable<any> {
    return this.http.post<any>(API.customers, product);
  }

  getCustomerById(productId: number): Observable<any> {
    return this.http.get<any>(API.customers + `/${productId}`);
  }

  getCustomerByCPR(cpr: string): Observable<any> {
    return this.http.get<any>(API.customers + `/cpr/${cpr}`);
  }

  updateCustomer(product, productId): Observable<any> {
    return this.http.put<any>(`${API.customers}/${productId}`, product);
  }

  getVariableInterest(params: any = {}): Observable<any> {
    return this.http.get<any>(API.settings + `/${SettingCodes.VARIABLE_INTEREST}`, {
      params: params,
    });
  }

  storePayment(product): Observable<any> {
    return this.http.post<any>(API.payments, product);
  }

  storeVehicleSinglePayment(payload: Object): Observable<any> {
    return this.http.post<any>(`${API.vehiclePayments}/single-payment`, payload);
  }

  storeVehicleMonthlyPayment(payload: Object): Observable<any> {
    return this.http.post<any>(`${API.vehiclePayments}/monthly-payment`, payload);
  }

  storeContractTransaction(product, entityId, entity_type): Observable<any> {
    return this.http.post<any>(`${API.transactions}/${entity_type}/${entityId}`, product);
  }

  moveVehicleWithTransaction(vehicle_id: number, payload): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicle_id}/move-vehicle`, payload);
  }

  prepareContractInvoice(product): Observable<any> {
    return this.http.post<any>(`${API.contractInvoice}/prepare`, product);
  }

  prepareVehicleInvoice(payload: Object): Observable<any> {
    return this.http.post<any>(`${API.vehicleInvoices}/prepare-invoice`, payload);
  }

  prepareEndContract(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/prepare-end-contract`, product);
  }

  prepareSellVehicleTask(contract_id, payload): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/prepare-sell-vehicle`, payload);
  }

  prepareSellVehicleInvoice(vehicle_id, payload): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicle_id}/prepare-sell-vehicle`, payload);
  }

  prepareSellFromInventoryVehicle(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${contract_id}/prepare-sell-vehicle-from-inventory`, product);
  }

  sendPaymentAdvice(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/payment-advice`, product);
  }

  sendVehiclePaymentAdvice(vehicle_id: number, payload): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${vehicle_id}/payment-advice`, payload);
  }

  getTransactionSetDetails(transaction_set_id) {
    return this.http.get<any>(API.transactionSets + `/${transaction_set_id}`);
  }

  deleteTransactionSetDocument(id, transaction_set_id, document_id, type) {
    if (type == "contract") {
      return this.http.delete<any>(API.transactionSets + `/${transaction_set_id}/delete-doc/${document_id}`, {
        params: { contract_id: id },
      });
    } else {
      return this.http.delete<any>(API.transactionSets + `/${transaction_set_id}/delete-doc/${document_id}`, {
        params: { asset_id: id },
      });
    }
  }

  updateTransactionSet(product, transaction_set_id): Observable<any> {
    return this.http.post<any>(`${API.transactionSets}/${transaction_set_id}`, product);
  }

  bookContractTransaction(product): Observable<any> {
    return this.http.post<any>(`${API.transactions}/book`, product);
  }

  getContractDetails(productId: number, entity_type): Observable<any> {
    if (entity_type === "contract") return this.http.get<any>(API.contracts + `/${productId}`);
    else return this.http.get<any>(API.offers + `/${productId}`);
  }

  getContractsList(vehicle_id: any, contract_status_code?: any[], vehicle_status_codes?: any[]) {
    if (contract_status_code == null && vehicle_status_codes == null) {
      return this.http.get<any>(`${API.contracts}/getAll?vehicle_id=${vehicle_id}`);
    } else {
      let contractStatusQuery = "";

      for (var i = contract_status_code.length - 1; i >= 0; i--) {
        contractStatusQuery += "contract_status_code[" + i + "]=" + contract_status_code[i] + "&";
      }
      console.log(contractStatusQuery);
      return this.http.get<any>(`${API.contracts}/getAll?
								${contractStatusQuery}
								vehicle_status_codes[0]=${vehicle_status_codes[0]}&
								vehicle_id=${vehicle_id}`);
    }
  }

  sellVehicle(product, contractId: any) {
    return this.http.post<any>(API.contracts + `/${contractId}/sell-vehicle`, product);
  }

  sellVehicleOption(vehicleId: number, payload: object) {
    return this.http.post<any>(API.vehicles + `/${vehicleId}/sell-vehicle`, payload);
  }

  sellVehicleFromInventory(product, contractId: any) {
    return this.http.post<any>(API.vehicles + `/${contractId}/sell-vehicle-from-inventory`, product);
  }

  endContract(product, contractId: any) {
    return this.http.post<any>(API.contracts + `/${contractId}/end-contract`, product);
  }

  getContractDocuments(contractId: any) {
    return this.http.get<any>(API.contracts + `/${contractId}/documents`);
  }

  generateDraftContract(contractId: any) {
    return this.http.get<any>(API.contracts + `/${contractId}/generate-draft-documents`);
  }

  sendContractForSigning(contractId: number, payload: any) {
    return this.http.post<any>(API.contracts + `/${contractId}/send-to-be-signed`, payload);
  }

  uploadSigningContract(product, contractId: any) {
    return this.http.post<any>(API.contracts + `/${contractId}/upload-signed`, product);
  }

  extendMe() {
    return this.http.get<any>(API.users + `/me`);
  }

  createInvoice(payload) {
    return this.http.post<any>(`${API.contractInvoice}/create`, payload);
  }

  createVehicleInvoice(payload) {
    return this.http.post<any>(`${API.vehicleInvoices}/create-invoice`, payload);
  }

  createFirstPaymentTask(contractId, payload) {
    return this.http.post<any>(`${API.contracts}/${contractId}/create-first-payments`, payload);
  }

  getDefaultText(used_vat) {
    return this.http.get<any>(API.settings + `/get-sell-vehicle-standard-text?used=${used_vat}`);
  }

  getContractOrCustomerNotes(productId, entityType): Observable<any> {
    return this.http.get<any>(API.Notes, {
      params: { [`${entityType}_id`]: productId },
    });
  }

  getLatestNoteOnPdf(entityId) {
    return this.http.get<any>(`${API.Notes}/${entityId}/latest-pdf-note`);
  }

  saveNote(entityType, payload) {
    payload.entity_type = entityType;
    return this.http.post<any>(API.Notes, payload);
  }

  updateNote(entityType, payload) {
    payload.entity_type = entityType;
    return this.http.put<any>(API.Notes + "/" + payload.id, payload);
  }

  deleteNote(entityType, payload) {
    payload.entity_type = entityType;
    return this.http.delete<any>(API.Notes + "/" + payload.id, payload);
  }

  destoryNote(noteId: number) {
    return this.http.delete<any>(`${API.Notes}/${noteId}`);
  }

  extendDate(entityType, entityId, payload) {
    payload.entity_type = entityType;
    return this.http.post<any>(API.contracts + "/" + entityId + "/extend-date", payload);
  }

  markContractTaskAsDone(contractId, taskId, payload) {
    return this.http.post<any>(API.contracts + `/${contractId}/mark-task/${taskId}/complete`, payload);
  }

  listCurrencies(q): Observable<any> {
    let params = "?q=" + q;
    return this.http.get<any>(`${API.Currencies}` + params);
  }

  getPostalCodeCity(zip_code: string): Observable<any> {
    return this.http.get<any>(`${API.lookups}/postal-code?zip_code=${zip_code}`);
  }

  getBirthdayFromCPR(cpr: string): Observable<string> {
    return this.http
      .get(`${API.customers}/birthdate-from-cpr`, {
        params: { cpr },
      })
      .pipe(map((res: { birthdate: string }) => res.birthdate));
  }

  getCustomerManagerName(contract: { contract_type: string; customers: any[] }) {
    if (contract?.customers) {
      if (contract.contract_type === EntityTypes.SPLIT_LEASING) {
        return contract.customers.filter((customer) => customer.customer_type_code === CustomerTypes.PRIVATE).pop()
          ?.customer.manager?.name;
      }
      return contract.customers[0]?.customer.manager?.name;
    }
    return null;
  }

  getContractInvoice(id): Observable<any> {
    return this.http.get(`${API.contractInvoice}/${id}/task-invoice`);
  }
  getVehicleInvoice(id): Observable<any> {
    return this.http.get(`${API.vehicles}/${id}/task-invoice`);
  }
}
