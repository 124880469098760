import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { INoteDetails, IPatchNote } from "./patch-note.interface";
import { API } from "../../../core/_base/crud";
import { Observable } from "rxjs";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { IMessageRequestPagination, IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { NotesTypesEnum } from "../../shared/enums/notes.enum";

@Injectable({
  providedIn: "root",
})
export class PatchNoteService {
  constructor(private http: HttpClient) {}

  getPatchNotesList(params: IMessageRequestPagination) {
    let httpParams = new HttpParams();
    for (let param in params) {
      httpParams = httpParams.append(param, params[param]);
    }
    return this.http.get<IResponsePagination<IPatchNote>>(API.MESSAGES.patch_note, { params: httpParams });
  }

  getPatchNoteById(id: string): Observable<IResponse<INoteDetails>> {
    return this.http.get<IResponse<INoteDetails>>(`${API.MESSAGES.patch_note}/${id}`);
  }

  getAnnouncements(): Observable<IResponse<Array<INoteDetails>>> {
    return this.http.get<IResponse<Array<INoteDetails>>>(API.MESSAGES.ANNOUNCEMENT);
  }

  getMessages(MessageType: NotesTypesEnum): Observable<IResponse<Array<INoteDetails>>> {
    return this.http.get<IResponse<Array<INoteDetails>>>(`${API.MESSAGES.patch_note}/${MessageType}`);
  }
}
